import Footer from 'components/footer/footer';
import Header from 'components/header/Header';
import React from 'react';
import './SalesLanding.scss';

const SalesLanding = () => {
  return (
    <>
      <Header />
      <div className="sales-container">
        <div className="sales-content">
          <h1>Sales Landing</h1>
          <p>This is the sales landing page</p>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default SalesLanding;
